import { useState } from 'react';
import { Button, useNotify, useRecordContext } from 'react-admin';

const ResendButton = (props: any) => {
  const record: any = useRecordContext(props);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const resend = async () => {
    if (!record.id) {
      notify('Invalid record ID', { type: 'error' });
      return;
    }

    const stringAuth = localStorage.getItem('auth');
    if (!stringAuth) {
      notify('Authentication token not found', { type: 'error' });
      return;
    }

    let auth;
    try {
      auth = JSON.parse(stringAuth);
    } catch (error) {
      notify('Invalid authentication data', { type: 'error' });
      return;
    }

    const baseApiUrl = process.env.REACT_APP_BASE_API_URL || '';
    if (!baseApiUrl) {
      notify('API URL not configured', { type: 'error' });
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(baseApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify({
          query: `
            mutation ResendEmail($input: resend_email_input!) {
              resend_email(params: $input) {
                success
              }
            }
          `,
          variables: {
            input: {
              id: record.id,
            },
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.errors) {
        const errorMessage = data.errors[0]?.message || 'Unknown GraphQL error';
        throw new Error(errorMessage);
      }

      if (data.data?.resend_email?.success) {
        notify('Email resent successfully', { type: 'success' });
      } else {
        notify('Failed to resend email: Operation unsuccessful', { type: 'warning' });
      }
    } catch (error) {
      console.error('Failed to resend email:', error);
      notify(
        error instanceof Error ? `Failed to resend email: ${error.message}` : 'Failed to resend email: Unknown error',
        { type: 'error' },
      );
    } finally {
      setLoading(false);
    }
  };

  if (record.is_sent) {
    return null;
  }

  return <Button label="Resend" onClick={resend} disabled={loading} />;
};

export default ResendButton;
